import React, { useState } from 'react'

import AuthUser from '../../components/AuthUser';
import { getListaPrecioCadenas } from '../../api/requestProductos';
import {bannerINGCO} from '../../assets/img';
import {
  portadaVert,
  portadaVertON,
  portadaIngco,
  portadaIngcoON,
  portadaWadfow,
  portadaWadfowON
} from '../../assets/img/';

const ExcelJS = require("exceljs")

export const ListaPrecioIngcoXLS = () => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const [data, setData] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true)

  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  
  
  
      
      const [showToast, setShowToast] = useState(true);
      const [vertImg, setVertImg] = useState(portadaVert);
      const [ingcoImg, setIngcoImg] = useState(portadaIngco);
      const [wadfowImg, setWadfowImg] = useState(portadaWadfow);
  
      const handleImageHover = (brand) => {
          switch (brand) {
              case 'vert':
                  setVertImg(portadaVert);
                  break;
              case 'vert-ON':
                  setVertImg(portadaVertON);
                  break;
              case 'ingco':
                  setIngcoImg(portadaIngco);
                  break;
              case 'ingco-ON':
                  setIngcoImg(portadaIngcoON);
                  break;
              case 'wadfow':
                  setWadfowImg(portadaWadfow);
                  break;
              case 'wadfow-ON':
                  setWadfowImg(portadaWadfowON);
                  break;
              default:
                  setVertImg(portadaVert);
                  setIngcoImg(portadaIngco);
                  setWadfowImg(portadaWadfow);
                  break;
          }
      };

  const exportExcelFile = async () => {
    setEnableBtn(false)
    const resProductos = await getListaPrecioCadenas(user.CodVendedor, ZonasVenta, 'INGCO')
    setData(resProductos)
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet("Lista de precios INGCO")

    var response = await fetch(bannerINGCO);

    const encabezadoRow = sheet.getRow(1)

    encabezadoRow.height = 25
    encabezadoRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FBA10B' }
    }
    encabezadoRow.font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' },
    }

    sheet.getCell('A2').value="Vendedor"
    sheet.getCell('B2').value=user.Nombre+" "+user.CodVendedor

    const buffer = await response.arrayBuffer();
    const imageId1 = workbook.addImage({
      buffer: buffer,
      extension: 'png',
    });
    sheet.addImage(imageId1, {
      tl: { col: 0, row: 2.5 },
      ext: { width: 1200, height: 130 },  
    });
    sheet.getRow(2).height = 25
    sheet.getRow(3).height = 25

    sheet.columns = [
      { header: "Codigo", key: "codigo", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Imagen", key: "imageId2", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Nombre", key: "nombre", width: 40, style: { alignment: { vertical: 'middle' } } },
      { header: "Precio", key: "precio", width: 10, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Existencia", key: "existencia", width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Cantidad Minima", key: "ventaMinima", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Codigo de Barras", key: "codigoBarras", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
    ]

    const promise = Promise.all(
      resProductos.map(async (product, index) => {
        const rowNumber = index + 1
        sheet.addRow({
          codigo: product.Codigo,
          rutaImagen: product.RutaImagen,
          nombre: product.Nombre,
          precio: product.Precio,
          existencia: product.Existencia,
          ventaMinima: product.VentaMinima,
          codigoBarras: product.CodigoBarras
        })
        const result = await toDataURL(product.RutaImagen)
        const splitted = product.RutaImagen.split(".")
        const extName = splitted[splitted.length - 1]

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        })

        sheet.addImage(imageId2, {
          tl: { col: 1, row: rowNumber + 2},
          ext: { width: 120, height: 120 },
        })
        sheet.getRow(rowNumber + 1).height = 95
      })
    )

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = "Lista de Precio Cadenas INGCO.xlsx"
        anchor.click()
        window.URL.revokeObjectURL(url)
        setEnableBtn(true)
      })
    })
  }

  return (
    <>
        {/* INGCO (Para pantallas medianas y superiores) */}
        <div className="col d-flex d-none d-md-block" onClick={exportExcelFile}>
            <a
                className="mx-auto portada"
                onMouseEnter={() => handleImageHover('ingco-ON')}
                onMouseLeave={() => handleImageHover('ingco')}>
                <img src={ingcoImg} className="px-0 rounded mx-auto portada" alt="hover" />
            </a>
        </div>

        {/* INGCO (Para pantallas pequeñas y extra pequeñas) */}
        <div className="col d-flex d-md-none" onClick={exportExcelFile}> {/* Se agrega d-md-none */}
            <div>
                <a>
                    <img src={ingcoImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </a>
            </div>
        </div>
    </>
);
}
