import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
COBRANZAS DE CLIENTE X VENDEDOR
==================================================================================
*/
export async function getCobranzasxCliente(Usuario) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasxCliente?Usuario=${Usuario}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getAllCobranzas(Usuario) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasxCliente?Usuario=${Usuario}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getDetallesCobranzas(Documento) {
  try {
    const res = await axios.get(baseURL + `/getDetallesCobranzas?Documento[eq]=${Documento}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCobranzasVendedorXLSX(codvendedor) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasVendedorXLSX?codvendedor=${codvendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function SearchCobranzas(CodUsuario, Filtro, Busqueda, fechaInicio, fechaFin) {
  try {
    let res;
    if (Filtro === 'Fecha') {
      res = await axios.get(baseURL + `/getCobranzasPorBusqueda?CodUsuario=${CodUsuario}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&whatToSearch=${Filtro}`);
    } else {
      res = await axios.get(baseURL + `/getCobranzasPorBusqueda?CodUsuario=${CodUsuario}&Busqueda=${Busqueda}&whatToSearch=${Filtro}`)
    }
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
MASTER DE COBRANZAS
==================================================================================
*/
export async function getListaMasterCobranzas() {
  try {
    const res = await axios.get(baseURL + `/getListaMasterCobranzas`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getListaMasterCobranzasZona(zona) {
  try {
    const res = await axios.get(baseURL + `/getListaMasterCobranzasZona?zona=${zona}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCobranzaVendedor(CodigoVendedor) {
  try {
    const res = await axios.get(baseURL + `/getCobranzaVendedor?CodigoVendedor=${CodigoVendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getListaDocVencidos(fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getListaDocVencidos?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getDellateDocVencido(CodigoVendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getDellateDocVencido?CodigoVendedor=${CodigoVendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

/*
==================================================================================
COBRANZAS PAGADAS, ABONADAS Y PENDIENTES POR ZONA E INICIO Y FINAL DE MES
==================================================================================
*/
export async function getInfoCobranzaPorZona(zona, firstday, lastday) {
  try {
    const res = await axios.get(baseURL + `/getInfoCobranzaPorZona?zona=${zona}&fechaInicio=${firstday}&fechaFin=${lastday}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCobranzasVendedorSupervisor(CodSupervisor, CodigoVendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasVendedorSupervisor?CodSupervisor=${CodSupervisor}&CodigoVendedor=${CodigoVendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCobranzasVendedorSupervisorGeneral(CodSupervisor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasVendedorSupervisorGeneral?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}