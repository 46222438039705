import { Row, Col } from 'react-bootstrap';
import {
  FileText, Truck, ScrollText, Contact, BarChart4, Goal, Video, HandCoins, FilePlus2, Clapperboard, ClipboardList, Coins, PackageSearch, ShoppingCart, PieChart, BarChartBig,
  GanttChart, Package, UsersRound
} from 'lucide-react';
import { Link } from 'react-router-dom';

export const menuDataVendedor =
  <Row className='text-start ms-2'>
    <Col>
      <h5 className='mb-2'>Documentos</h5>
      <h6><a href="/orders"><FileText /> Pedidos</a></h6>
      <h6 className='my-1'><a href="/invoices"><FileText /> Facturas</a></h6>
      <h6><a href="/payments"><HandCoins /> Cobranzas</a></h6>
      <h6 className='my-1'><a href="/cargo-manifest"><ScrollText /> Manifiestos</a></h6>
      <h6><a href="/preorders"><FileText /> Consulta Presupuestos</a></h6>
      <h6 className='my-1'><a href="/make-preorders"><FilePlus2 /> Nuevo Presupuesto</a></h6>
    </Col>
    <Col>
      <h5 className='mb-2'>Información General</h5>
      <h6><a href="/clients"><Contact /> Clientes</a></h6>
      <h6 className='my-1'><a href="/tracking"><Truck /> Tracking</a></h6>
      <h6><a href="/tutorials"><Video /> Tutoriales</a></h6>
      <h6><a href="/price-list2"><FileText /> Lista de Precios</a></h6>
      <h6><a href="/top-list"><FileText /> Consulta TOP 100</a></h6>
    </Col>
  </Row>;

export const menuDataVideo =
  <Row className='text-start ms-2'>
    <Col>
      <h5 className='mb-2'>Videos</h5>
      <h6><a href="/register-videos"><Clapperboard /> Crear nuevo vídeo</a></h6>
      <h6 className='my-1'><a href="/tutorials"><Video /> Ver vídeos</a></h6>
    </Col>
  </Row>;

export const menuDataSupervisor =
  <Row className='text-start ms-2'>
    <Col>
      <h5 className='mb-2'>Documentos</h5>
      <h6><a href="/orders"><FileText /> Pedidos</a></h6>
      <h6 className='my-1'><a href="/invoices"><FileText /> Facturas</a></h6>
      <h6><a href="/payments"><HandCoins /> Cobranzas</a></h6>
      <h6 className='my-1'><a href="/invoices"><PackageSearch /> Incidencias</a> <span className="badge badge-secondary bg-info">PRONTO</span></h6>
      <h6 className='my-1'><a href="/invoices"><ShoppingCart /> Presupuestos</a> <span className="badge badge-secondary bg-info">PRONTO</span></h6>
      <h6 className='my-1'><a href="/operations/tracking"><Truck /> Tracking </a></h6>
      <h6><a href="/manifiesto-supervisor"><ScrollText /> Manifiesto</a></h6>
    </Col>
    <Col>
      <h5 className='mb-2'>Información General</h5>
      <h6><a href="/clients"><Contact /> Clientes</a></h6>
      <h6 className='my-1'><a href="/zone-stats"><BarChart4 /> Estadisticas</a></h6>
      <h6><a href="/goals"><Goal /> Metas</a></h6>
      <h6 className='my-1'><a href="/tutorials"><Video /> Tutoriales</a></h6>
    </Col>
  </Row>;

export const menuDataGerente =
  <Row className='text-start ms-2'>
    <Col>
      <h5 className='mb-2'>Documentos</h5>
      <h6><a href="/orders"><FileText />Pedidos</a></h6>
      <h6 className='my-1'><a href="/invoices"><FileText />Facturas</a></h6>
      <h6><a href="/payments"><HandCoins />Cobranzas</a></h6>
    </Col>
    <Col>
      <h5 className='mb-2'>Información General</h5>
      <h6><a href="/clients"><Contact />Clientes</a></h6>
      <h6 className='my-1'><a href="/appraisals"><BarChart4 />Estimaciones de Cobranzas</a></h6>
    </Col>
  </Row>;

export const ModulosSupervisor = ({ children }) => {
  return (
    <>
      <div className="row">
        <div className="col text-center">
          <a className='bg-white rounded-pill p-2 mb-2 btn btn-hover' href="/orders">
            <ClipboardList size={72} color="#0043b0" className='p-2' />
          </a>
          <h6>Pedidos</h6>
        </div>
        <div className="col text-center">
          <a className='bg-white rounded-pill p-2 mb-2 btn btn-hover' href="/invoices">
            <ClipboardList size={72} color="#0043b0" className='p-2' />
          </a>
          <h6>Facturas</h6>
        </div>
        <div className="col text-center">
          <a className='bg-white rounded-pill p-2 mb-2 btn btn-hover' href="/payments">
            <Coins size={72} color="#0043b0" className='p-2' />
          </a>
          <h6>Cobranzas</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <PackageSearch size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Incidencias</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <ShoppingCart size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Presupuestos</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col-3 text-center">
          <a className='bg-white rounded-pill p-2 mb-2 btn btn-hover' href="/operations/tracking">
            <PackageSearch size={72} color="#0043b0" className='p-2' />
            
          </a>
          <h6>Tracking</h6>
        </div>
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
          <a  href="/manifiesto-supervisor">
            <Truck size={72} color="#0043b0" className='p-2'/>
            </a>
          </div>
          <h6>Manifiestos</h6>
          <span className="badge bg-info"></span>
        </div>
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('topVendedores')}>
            <BarChartBig size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Top vendedores</h6>
        </div>
      </div>
      <div className="row my-4">
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('pedidosPorDia')}>
            <BarChart4 size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Pedidos por día</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('topProductos')}>
            <Package size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Top productos</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('corteSemanal')}>
            <GanttChart size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Corte semanal</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'> {/* WIP: Add <Link to="/dashboard-supervisor/gestion-vendedores"></> */}
            <Link to="/seller-management"><UsersRound size={72} color="#0043b0" className='p-2' /></Link>
          </div>
          <h6>Gestión de Vendedores</h6>
        </div>
      </div>
    </>
  )
}

export const ModulosGerente = ({ children }) => {
  return (
    <>
      <div className="row">
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <ClipboardList size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Pedidos</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <ClipboardList size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Facturas</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <Coins size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Cobranzas</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <PackageSearch size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Incidencias</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <ShoppingCart size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Presupuestos</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <PackageSearch size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Tracking</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col-3 text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover'>
            <Truck size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Manifiestos</h6>
          <span className="badge bg-info">PRONTO</span>
        </div>
        <div className="col-3 text-center">
          <a className='bg-white rounded-pill p-2 mb-2 btn btn-hover' href="/appraisals">
            <BarChart4 size={72} color="#0043b0" className='p-2' />
          </a>
          <h6>Estimaciones</h6>
        </div>
      </div>
      <div className="row my-4">
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('corteSemanal')}>
            <BarChart4 size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Corte semanal</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('pedidosPorDia')}>
            <ClipboardList size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Pedidos por día</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('ranking')}>
            <BarChart4 size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Ranking vendedores</h6>
        </div>
        <div className="col text-center">
          <div className='bg-white rounded-pill p-2 mb-2 btn btn-hover' onClick={() => children('cobranzasZona')}>
            <PieChart size={72} color="#0043b0" className='p-2' />
          </div>
          <h6>Cobranzas por zona</h6>
        </div>
      </div>
    </>
  )
}