import React, { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import AuthUser from '../components/AuthUser';

import belmenyLogo from '../assets/img/logoBG.webp';
import ingcoLogo from '../assets/img/LOGO-INGCO-PNG.webp';
import vertLogo from '../assets/img/logo-vert.webp';
import wadfowLogo from '../assets/img/logos/WADFOW-SVG.svg'
import otherUserIcon from '../assets/img/user-icon.webp';

import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

import Chart from "react-apexcharts";

import axios from 'axios';

import { getMetasPorVendedor, getTopVendedoresZona, getInfoCobranza, getVentasAnualesEnCurso, getVentasVendedor, getClientesAtendidos,getTopClientesVendedor,getPorcentajeClientes, getPorcentajeVendedor } from '../api/request';

import { formatNumber } from '../utils/formats';

export const Dashboard = () => {

  useEffect(() => {
    loadMetasYProgreso()
    CheckImg()
  }, []);
  // State del usuario
  const { user } = AuthUser();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de datos
  const [metas, setMetas] = useState([]);

  const [chartData, setChartData] = useState({});
  const [topData, setTopData] = useState({});
  const [topDataMobile, setTopDataMobile] = useState({});
  const [porcentajeVendedor, setPorcentajeVendedor] = useState({});
  const [topClienteMobile, setTopClienteMobile] = useState({});
  const [topCliente, setTopCliente] = useState({});
  const [porcentajeCliente, setPorcentajeCliente] = useState({});
  const [porcentajeClienteMobile, setPorcentajeClienteMobile] = useState({});
  const [pieDataActualMonth, setPieDataActualMonth] = useState({});
  const [pieDataLastMonth, setPieDataLastMonth] = useState({});
  const [options, setOptions] = useState({})
  const [userIcon, setUserIcon] = useState(``)

  // States de fechas
  const [lastMonth, setLastMonth] = useState('');
  const [past2Month, setPast2Months] = useState('');

  const now = new Date();

  const CheckImg = async () => {
    await axios.get(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`).then(() => {
      setUserIcon(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`)
      return true;
    })
      .catch(() => {
        setUserIcon(otherUserIcon)
        return false;
      });
  }

  const defaultBrokenImg = (e) => {
    e.target.src = otherUserIcon
  }

  const loadMetasYProgreso = async () => {
    // Load all request here
    const metasRes = await getMetasPorVendedor(user.CodVendedor);
    setMetas(metasRes.data[0])

    // Hace 2 meses
    const past2Months = new Date(now.getFullYear(), now.getMonth() - 2, 1)
    const firstDayTwoMonths = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 2, 1)).slice(1, 11)
    const lastDayTwoMonths = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 1, 0)).slice(1, 11)

    // Hace 1 mes
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const firstDayLastMonth = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 1, 1)).slice(1, 11);
    const lastDayLastMonth = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 0)).slice(1, 11);

    // Mes actual
    const actualMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const firstDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 1)).slice(1, 11);
    const lastDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth() + 1, 0)).slice(1, 11);

    setPast2Months(past2Months.toLocaleString('default', { month: 'long' }))
    setLastMonth(lastMonth.toLocaleString('default', { month: 'long' }))

    function sortByMonth(arr) {
      var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
      arr.sort(function (a, b) {
        return months.indexOf(a.mes)
          - months.indexOf(b.mes);
      });
    }

    const ventas2022 = await getVentasVendedor('2022', user.CodVendedor)
    const ventas2023 = await getVentasVendedor('2023', user.CodVendedor)

    sortByMonth(ventas2022)
    sortByMonth(ventas2023)

    const ventasAnualActualRes = await getVentasAnualesEnCurso(user.CodVendedor)

    const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    //consulta para obtener los top clientes del vendedor
    const topclientes= await getTopClientesVendedor(now.getFullYear(),labels[now.getMonth()].toUpperCase(),user.CodVendedor)

    const porcentajeClientes= await getPorcentajeClientes(user.CodVendedor)
    setPorcentajeVendedor(await getPorcentajeVendedor(user.CodVendedor))
    
    const dataActualYear = labels.map(label => (ventasAnualActualRes[label] === null) ? 0 : ventasAnualActualRes[label].toFixed(2));
    const dataVentas2023 = labels.map((mes) => {
      const existingMes = ventas2023.find((venta) => venta.mes.toLowerCase() === mes.toLowerCase());
      return existingMes ? existingMes : { vendedor: 'V161', ventas: 0, mes, year: 2023 };
    });

    const dataVentas2022 = labels.map((mes) => {
      const existingMes = ventas2022.find((venta) => venta.mes.toLowerCase() === mes.toLowerCase());
      return existingMes ? existingMes : { vendedor: 'V161', ventas: 0, mes, year: 2022 };
    });


    if ((dataVentas2023.length === 0 || dataVentas2023.length === undefined) && (dataVentas2022.length === 0 || dataVentas2022.length === undefined)) {
      setChartData({
        options: {
          chart: {
            id: "chart-VentasAnuales",
            type: "area"
          },
          xaxis: {
            categories: labels
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
          colors: ['#52d689'],
          dataLabels: {
            enabled: true
          },
        },
        series: [
          {
            name: 'Ventas en USD del año actual',
            data: dataActualYear
          }
        ]
      })
    }
    else if ((dataVentas2022.length === 0 || dataVentas2022.length === undefined) && dataVentas2023.length !== 0) {
      setChartData({
        options: {
          chart: {
            id: "chart-VentasAnuales",
            type: "area"
          },
          xaxis: {
            categories: labels
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
          colors: ['#52d689', '#0041ff'],
          dataLabels: {
            enabled: false
          },
        },
        series: [
          {
            name: `Ventas en USD del año ${dataVentas2023[0].year}`,
            data: dataVentas2023.map((Vendedor) => Vendedor.ventas.toFixed(2))
          },
          {
            name: 'Ventas en USD del año actual',
            data: dataActualYear
          }
        ]
      })
    }
    else if (dataVentas2022.length !== 0 && (dataVentas2023.length === 0 || dataVentas2023.length === undefined)) {
      setChartData({
        options: {
          chart: {
            id: "chart-VentasAnuales",
            type: "area"
          },
          xaxis: {
            categories: labels
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
          colors: ['#52d689', '#0041ff'],
          dataLabels: {
            enabled: false
          },
        },
        series: [
          {
            name: 'Ventas en USD del año actual',
            data: dataActualYear
          },
          {
            name: `Ventas en USD del año ${dataVentas2022[0].year}`,
            data: dataVentas2022.map((Vendedor) => Vendedor.ventas.toFixed(2))
          },
        ]
      })
    }
    else {
      setChartData({
        options: {
          chart: {
            id: "chart-VentasAnuales",
            type: "area"
          },
          xaxis: {
            categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
          colors: ['#52d689', '#0041ff', '#d62915'],
          dataLabels: {
            enabled: false
          },
        },
        series: [
          {
            name: `Ventas del año ${dataVentas2022[0].year} en USD`,
            data: dataVentas2022.map((Vendedor) => Vendedor.ventas.toFixed(2))
          },
          {
            name: `Ventas del año ${dataVentas2023[0].year} en USD`,
            data: dataVentas2023.map((Vendedor) => Vendedor.ventas.toFixed(2))
          },
          {
            name: 'Ventas en USD del año actual',
            data: dataActualYear
          }
        ]
      });
    }

    const topRes = await getTopVendedoresZona(metasRes.data[0].zona, firstDay, lastDay)

    const newtopRes = topRes.filter((item) => {
      return item.vendedor !== 'V1' && item.vendedor !== 'V2' && item.vendedor !== 'T1'
    })

    setTopData({
      options: {
        chart: {
          id: "chart-topVendedores",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: newtopRes.map((Vendedor) => Vendedor.Nombre.toUpperCase())
        },
        colors: newtopRes.map((Vendedor) => Vendedor.total_vendido < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.total_vendido < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        dataLabels: {
          enabled: false
        },
      },
      series: [{
        name: 'Ventas en USD',
        data: newtopRes.map((Ventas) => Ventas.total_vendido)
      }]
    })

    setTopCliente({
      options: {
        chart: {
          id: "chart-topCliente",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: topclientes.map((cliente) => cliente.nombrecli)
        },
        colors: newtopRes.map((Vendedor) => Vendedor.total_vendido < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.total_vendido < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        dataLabels: {
          enabled: false
        },
      },
      series: [{
        name: 'Ventas en USD',
        data: topclientes.map((cliente) => cliente.ventas)
      }]
    })

    setTopClienteMobile({
      options: {
        chart: {
          id: "chart-topClientes",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: topclientes.map((cliente) => cliente.nombrecli)
        },
        colors: newtopRes.map((Vendedor) => Vendedor.total_vendido < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.total_vendido < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        dataLabels: {
          enabled: false
        },
      },
      series: [{
        name: 'Ventas en USD',
        data: topclientes.map((cliente) => cliente.ventas)
      }]
    })

    setPorcentajeCliente({
      options: {
          chart: {
              id: "chart-porcentajeCliente",
              type: "bar",
              height: '100%', // Para que el gráfico ocupe el 100% del contenedor
              stacked: true, //Para que las barras se apilen
          },
          plotOptions: {
              bar: {
                  borderRadius: 4,
                  borderRadiusApplication: 'end',
                  horizontal: true,
                  barHeight:'80%' //Ajusta el grosor de las barras
              }
          },
          xaxis: {
              categories: porcentajeClientes.map((cliente) => cliente.Nombre),
          },
          yaxis:{
            labels:{
              style: {
                fontSize: '10px' // Reduce el tamaño de la fuente del eje y
              }
            }
          },
          colors:['#fba10b','#0041ff'],
          legend:{
            position:'top',
            horizontalAlign: 'left' //Alinea la leyenda
          }
      },
      series: [{
          name: 'Porcentaje de compra de INGCO',
          data: porcentajeClientes.map((cliente) => cliente.porcentaje_ingco)
      },
      {
          name: 'Porcentaje de compra de VERT',
          data: porcentajeClientes.map((cliente) => cliente.porcentaje_vert)
      }]
  });

    setPorcentajeClienteMobile({
      options: {
        chart: {
          id: "chart-porcentajeCliente",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: porcentajeClientes.map((cliente) => cliente.Nombre)
        },
        colors: ['#fba10b','#0041ff'],
        dataLabels: {
          enabled: false
        },
      },
      series: [{
        name: 'Porcentaje de compra de INGCO',
        data: porcentajeClientes.map((cliente) => cliente.porcentaje_ingco)
      },
      {
        name: 'Porcentaje de compra de VERT',
        data: porcentajeClientes.map((cliente) => cliente.porcentaje_vert)
      }]
    })
    
    if (newtopRes.length > 2) {
      setTopDataMobile({
        options: {
          chart: {
            id: "chart-topVendedores",
            type: "bar"
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            }
          },
          xaxis: {
            categories: [newtopRes[0].Nombre, newtopRes[1].Nombre, newtopRes[2].Nombre, newtopRes[3].Nombre]
          },
          colors: newtopRes.map((Vendedor) => Vendedor.total_vendido < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.total_vendido < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
          dataLabels: {
            enabled: false
          },
        },
        series: [{
          name: 'Ventas en USD',
          data: [newtopRes[0].total_vendido, newtopRes[1].total_vendido, newtopRes[2].total_vendido, newtopRes[3].total_vendido]
        }]
      })
    }
    else {
      setTopDataMobile({
        options: {
          chart: {
            id: "chart-topVendedores",
            type: "bar"
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            }
          },
          xaxis: {
            categories: [newtopRes[0].Nombre, newtopRes[1].Nombre]
          },
          colors: newtopRes.map((Vendedor) => Vendedor.total_vendido < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.total_vendido < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
          dataLabels: {
            enabled: false
          },
        },
        series: [{
          name: 'Ventas en USD',
          data: [newtopRes[0].total_vendido, newtopRes[1].total_vendido]
        }]
      })
    }

    const cobranzasResLastMonth = await getInfoCobranza(user.CodVendedor, firstDayTwoMonths, lastDayTwoMonths)
    // Cobranza de hace 2 meses
    setPieDataLastMonth({
      options: {
        chart: {
          id: "chart-cobranzasMesPasado",
          type: "pie",
          width: "150px"
        },
        labels: [`${((cobranzasResLastMonth.Pagado[0].Pagado * 100) / (cobranzasResLastMonth.Pagado[0].Pagado + cobranzasResLastMonth.Pendiente[0].Pendiente)).toFixed(2)}% Pagado`, `${((cobranzasResLastMonth.Pendiente[0].Pendiente * 100) / (cobranzasResLastMonth.Pagado[0].Pagado + cobranzasResLastMonth.Pendiente[0].Pendiente)).toFixed(2)}% Pendiente`],
        colors: ['#27ae60b3', '#ff0000b3'],
        legend: {
          position: 'bottom'
        }
      },
      series: [
        (cobranzasResLastMonth.Pagado[0].Pagado === null) ? 0 : cobranzasResLastMonth.Pagado[0].Pagado,
        (cobranzasResLastMonth.Pendiente[0].Pendiente === null) ? 0 : cobranzasResLastMonth.Pendiente[0].Pendiente
      ]
    })

    // Cobranza de hace 1 mes
    const cobranzasResActualMonth = await getInfoCobranza(user.CodVendedor, firstDayLastMonth, lastDayLastMonth)

    setPieDataActualMonth({
      options: {
        chart: {
          id: "chart-cobranzasMesActual",
          type: "pie",
          width: "150px"
        },
        labels: [`${((cobranzasResActualMonth.Pagado[0].Pagado * 100) / (cobranzasResActualMonth.Pagado[0].Pagado + cobranzasResActualMonth.Pendiente[0].Pendiente)).toFixed(2)}% Pagado`, `${((cobranzasResActualMonth.Pendiente[0].Pendiente * 100) / (cobranzasResActualMonth.Pagado[0].Pagado + cobranzasResActualMonth.Pendiente[0].Pendiente)).toFixed(2)}% Pendiente`],
        colors: ['#27ae60b3', '#ff0000b3'],
        legend: {
          position: 'bottom'
        }
      },
      series: [
        (cobranzasResActualMonth.Pagado[0].Pagado === null) ? 0 : cobranzasResActualMonth.Pagado[0].Pagado,
        (cobranzasResActualMonth.Pendiente[0].Pendiente === null) ? 0 : cobranzasResActualMonth.Pendiente[0].Pendiente,
      ]
    })

    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span className="loader-dashboard"></span>
                </div>
              </> :
              <>

                {/* Dashboard for mobile version */}
                <div className="d-sm-block d-md-none">
                  <div className="my-4">
                    <div className="row">
                      <div className="col w-25">
                        <div className="text-belmeny">
                          <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3' />
                          <h3 className=""><strong>Bienvenido</strong></h3>
                          <h4 className='fs-3'>{user.Nombre}</h4>
                          <h5><i>{user.CodVendedor}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Imagén de usuario" className='float-end drop-shadow rounded' style={{ width: "150px", height: "150px", border: "3px solid rgba(0, 41, 117, 1)" }} onError={defaultBrokenImg} />
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-2 mb-3">
                    <h4 className='bg-belmeny fw-light text-light px-5 rounded-pill'>Metas establecidas para el mes en curso</h4>
                  </div>

                  <div className="bg-belmeny division mb-2"></div>

                  {/* Metas y sus montos */}
                  {/* GLOBAL */}
                  <div className="row mx-auto">
                    <div className="col">
                      <div className="card my-2 drop-shadow-sm rounded-card bg-belmeny">
                        <div className="card-body rounded-card pt-4">
                          <div className="row">
                            <div className="col-4">
                              <img src={belmenyLogo} alt="" className='w-100 pb-2' />
                            </div>
                            <div className="col">
                              <h3 className="text-end card-title fw-normal">Meta: ${formatNumber(metas.ingco + metas.vert + metas.wadfow)}</h3>
                            </div>
                          </div>
                          <h4 className="card-text text-end fw-normal fs-4">Ventas: ${formatNumber(metas.total_vendido)} ≈ {formatNumber((metas.total_vendido / (metas.ingco + metas.vert + metas.wadfow)) * 100)}%</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-light">
                    {/* WADFOW */}
                    <div className="row mx-auto">
                      <div className="col">
                        <div className="card my-2 drop-shadow-sm rounded-card bg-belmeny">
                          <div className="card-body rounded-card pt-4">
                            <div className="row">
                              <div className="col-4">
                                <img src={wadfowLogo} alt="" className='w-100 pb-2' />
                              </div>
                              <div className="col">
                                <h3 className="text-end card-title fw-normal">Ventas: ${formatNumber(metas.VentasWadfow)}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* VËRT */}
                    <div className="row mx-auto">
                      <div className="col">
                        <div className="card my-2 drop-shadow-sm rounded-card bg-belmeny">
                          <div className="card-body rounded-card pt-4">
                            <div className="row">
                              <div className="col-4">
                                <img src={vertLogo} alt="" className='w-100 pb-2' />
                              </div>
                              <div className="col">
                                <h3 className="text-end card-title fw-normal">Ventas: ${formatNumber(metas.VentasVert)}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* INGCO */}
                    <div className="row mx-auto">
                      <div className="col">
                        <div className="card my-2 drop-shadow-sm rounded-card bg-belmeny">
                          <div className="card-body rounded-card pt-4">
                            <div className="row">
                              <div className="col-4">
                                <img src={ingcoLogo} alt="" className='pt-2 w-100' />
                              </div>
                              <div className="col">
                                <h3 className="text-end card-title fw-normal">Ventas: ${formatNumber(metas.VentasIngco)}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-belmeny division mt-4"></div>

                  {/* Chart ventas anuales 2022-2023-2024 */}
                  <h5 className="text-center text-belmeny mt-4">Ventas Anuales</h5>
                  <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="area"
                    width="100%"
                    height="400px"
                  />

                  <div className="bg-belmeny division my-3"></div>

                  <h5 className="text-center text-belmeny mt-4">Información de cobranzas</h5>
                  <div className="w-100 m-auto mb-2">
                    <h6 className='text-center text-belmeny mt-2' style={{ textTransform: 'capitalize' }}>{lastMonth}</h6>
                    <Chart
                      options={pieDataActualMonth.options}
                      series={pieDataActualMonth.series}
                      type="pie"
                    />
                  </div>

                  <div className="w-100 m-auto mb-2">
                    <h6 className='text-center text-belmeny mt-2' style={{ textTransform: 'capitalize' }}>{past2Month}</h6>
                    <Chart
                      options={pieDataLastMonth.options}
                      series={pieDataLastMonth.series}
                      type="pie"
                    />
                  </div>

                  <div className="bg-belmeny division my-3"></div>

                  <Chart
                    options={topDataMobile.options}
                    series={topDataMobile.series}
                    type="bar"
                    width="100%"
                    height="500px"
                  />

                 {/*  <div className="bg-belmeny division my-3"></div>

                  <Chart
                    options={topClienteMobile.options}
                    series={topClienteMobile.series}
                    type="bar"
                    width="100%"
                    height="500px"
                  />*/}

                  <div className="bg-belmeny division my-3"></div>
                  <h5 className="text-center text-belmeny mt-4">Porcentaje de Top Productos Comprados por Cliente</h5>
                              <h5 className="text-center text-ingco">Promedio INGCO: {porcentajeVendedor.porcen_ingco}%</h5>
                              <h5 className="text-center text-belmeny">Promedio VERT: {porcentajeVendedor.porcen_vert}%</h5>
                  <Chart
                    options={porcentajeClienteMobile.options}
                    series={porcentajeClienteMobile.series}
                    type="bar"
                    width="100%"
                    height="500px"
                  />
                  <div className='mt-5 bg-belmeny division' />
                  <div className="text-end mb-3">
                    <a href="https://www.facebook.com/belmeny.vert/" target={'_blank'} rel='noreferrer' className='fb-btn text-belmeny me-3'>
                      <span className='fs-1'><FaFacebook /></span>
                    </a>
                    <a href="https://twitter.com/vert_productos?lang=es" target={'_blank'} rel='noreferrer' className='tw-btn text-belmeny me-3'>
                      <span className='fs-1'><FaTwitter /></span>
                    </a>
                    <a href="https://www.instagram.com/vert.productos/?hl=es" target={'_blank'} rel='noreferrer' className='ig-btn text-belmeny me-3'>
                      <span className='fs-1'><FaInstagram /></span>
                    </a>
                  </div>
                </div>

                {/* Dashboard for web version */}
                <div className='d-none d-md-block d-lg-block'>
                  <div className="container-fluid rounded">
                    {/* Welcome text & UserProfile */}
                    <div className="row">
                      <div className="col">
                        <div className="text-belmeny">
                          <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                          <h3>{user.Nombre}</h3>
                          <h5><i>{user.CodVendedor}</i></h5>
                        </div>
                      </div>
                      {/* User image */}
                      <div className="col">
                        <img src={userIcon} alt="No se ha podido cargar la foto..." className='float-end me-5 drop-shadow-sm rounded' style={{ width: "150px", height: "150px", border: "3px solid rgba(0, 41, 117, 1)" }} onError={defaultBrokenImg} />
                      </div>
                    </div>

                    <div className="d-sm-md-view">
                      <div className="dashboard-title mt-4 mb-3">
                        <h4 className='bg-belmeny text-light px-5 rounded-pill'>Metas establecidas para el mes en curso</h4>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <h5 className="text-center text-belmeny mt-4">Ventas Anuales</h5>
                          <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>

                      <div className='mt-5 text-belmeny division' />

                      <div className="row">
                        {/* GLOBAL */}
                        <div className="col-4">
                          <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                            <div className="card-body">
                              <img src={belmenyLogo} alt="Logo BELMENY GROUP" className='card-title' style={{ width: "100px" }} />
                              <h6 className="card-text mb-1">
                                <strong className='text-start'>Meta: ${formatNumber(metas.ingco + metas.vert + metas.wadfow)}</strong> <br />
                                <strong className='text-end'>Ventas: ${formatNumber(metas.total_vendido)} ≈ {formatNumber((metas.total_vendido / (metas.ingco + metas.vert + metas.wadfow)) * 100)}%</strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* VERT */}
                        <div className="col-4">
                          <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                            <div className="card-body">
                              <img src={vertLogo} alt="Logo VËRT" className='card-title' style={{ width: "130px" }} />
                              <h6 className="card-text mb-1">
                                <strong className='text-end'>Ventas: ${formatNumber(metas.VentasVert)}</strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* WADFOW */}
                        <div className="col-4">
                          <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                            <div className="card-body">
                              <img src={wadfowLogo} alt="Logo WADFOW" className='card-title' style={{ width: "130px" }} />
                              <h6 className="card-text mb-1">
                                <strong className='text-end'>Ventas: ${formatNumber(metas.VentasWadfow)}</strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* INGCO */}
                        <div className="col-4">
                          <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                            <div className="card-body">
                              <img src={ingcoLogo} alt="Logo INGCO" className='card-title' style={{ width: "220px" }} />
                              <h6 className="card-text mb-1">
                                <strong className='text-end'>Ventas: ${formatNumber(metas.VentasIngco)}</strong>
                              </h6>
                            </div>
                          </div>
                        </div>


                        <div className='mt-3 text-belmeny division' />

                        <div className="row">
                          <div className="col-6">
                            <div className="w-80 m-auto mb-2">
                              {/* Pie chart 1 mes antes */}
                              <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {lastMonth}</h5>
                              <Chart
                                options={pieDataActualMonth.options}
                                series={pieDataActualMonth.series}
                                type="pie"
                                className='mt-2'
                              />
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="w-80 m-auto mb-2">
                              {/* Pie chart 2 meses antes */}
                              <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {past2Month}</h5>
                              <Chart
                                options={pieDataLastMonth.options}
                                series={pieDataLastMonth.series}
                                type="pie"
                                className='mt-2'
                              />
                            </div>
                          </div>
                        </div>

                        <div className='mt-3 text-belmeny division' />

                        <div className="row">
                          <div className="col-12">
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Top Vendedores</h5>
                              <Chart
                                options={topData.options}
                                series={topData.series}
                                type="bar"
                                width="100%"
                                height="500px"
                              />
                            </div>

                            <div className='mt-3 ' />

                        <div className="row">
                          
                           {/*  <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Top Clientes</h5>
                              <Chart
                                options={topCliente.options}
                                series={topCliente.series}
                                type="bar"
                                width="100%"
                                height="500px"
                              />*/}
                            
                            
                            </div>
                            <div className='mt-3 text-belmeny division' />
                            <div className="row">
                          
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Porcentaje de Top Productos Comprados por Cliente</h5>
                              <h5 className="text-center text-ingco">Promedio INGCO: {porcentajeVendedor.porcen_ingco}%</h5>
                              <h5 className="text-center text-belmeny">Promedio VERT: {porcentajeVendedor.porcen_vert}%</h5>
                              <Chart
                                options={porcentajeCliente.options} 
                                series={porcentajeCliente.series}
                                type="bar"
                                width="100%"
                                height="800px"
                              />
                            
                            </div>
                            </div>
                            <div className='mt-5 text-belmeny division' />
                            <div className="mt-2 text-end">
                              <a href="https://www.facebook.com/belmeny.vert/" target={'_blank'} rel='noreferrer' className='fb-btn text-belmeny me-3'>
                                <span className='fs-1'><FaFacebook /></span>
                              </a>
                              <a href="https://twitter.com/vert_productos?lang=es" target={'_blank'} rel='noreferrer' className='tw-btn text-belmeny me-3'>
                                <span className='fs-1'><FaTwitter /></span>
                              </a>
                              <a href="https://www.instagram.com/vert.productos/?hl=es" target={'_blank'} rel='noreferrer' className='ig-btn text-belmeny me-3'>
                                <span className='fs-1'><FaInstagram /></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-md-lg-view">
                      <div className="dashboard-title mt-2 mb-3">
                        <h4 className='bg-belmeny text-light px-5 rounded-pill'>Metas establecidas para el mes en curso</h4>

                        <div className="row">
                          <div className="col-12">
                            <Chart
                              options={chartData.options}
                              series={chartData.series}
                              type="area"
                              width="100%"
                              height="350px"
                            />
                          </div>
                        </div>

                        <div className='mt-5 text-belmeny division' />

                        <div className="row">
                          {/* GLOBAL */}
                          <div className="col-4">
                            <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                              <div className="card-body">
                                <img src={belmenyLogo} alt="Logo BELMENY GROUP" className='card-title' style={{ width: "100px" }} />
                                <h6 className="card-text mb-1">
                                  <strong className='text-start'>Meta: ${formatNumber(metas.ingco + metas.vert + metas.wadfow)}</strong> <br />
                                  <strong className='text-end'>Ventas: ${formatNumber(metas.total_vendido)} ≈ {formatNumber((metas.total_vendido / (metas.ingco + metas.vert + metas.wadfow)) * 100)}%</strong>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* VERT */}
                          <div className="col-4">
                            <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                              <div className="card-body">
                                <img src={vertLogo} alt="Logo VËRT" className='card-title' style={{ width: "130px" }} />
                                <h6 className="card-text mb-1">
                                  <strong className='text-end'>Ventas: ${formatNumber(metas.VentasVert)}</strong>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* WADFOW */}
                          <div className="col-4">
                            <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                              <div className="card-body">
                                <img src={wadfowLogo} alt="Logo WADFOW" className='card-title' style={{ width: "130px" }} />
                                <h6 className="card-text mb-1">
                                  <strong className='text-end'>Ventas: ${formatNumber(metas.VentasWadfow)}</strong>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* INGCO */}
                          <div className="col-4">
                            <div className="card bg-belmeny mt-5 text-light pb-0 drop-shadow">
                              <div className="card-body">
                                <img src={ingcoLogo} alt="Logo INGCO" className='card-title' style={{ width: "220px" }} />
                                <h6 className="card-text mb-1">
                                  <strong className='text-end'>Ventas: ${formatNumber(metas.VentasIngco)}</strong>
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div className='mt-3 text-belmeny division' />

                          <div className="row">
                            <div className="col-6">
                              <div className="w-80 m-auto mb-2">
                                {/* Pie chart 1 mes antes */}
                                <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {lastMonth}</h5>
                                <Chart
                                  options={pieDataActualMonth.options}
                                  series={pieDataActualMonth.series}
                                  type="pie"
                                  className='mt-2'
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="w-80 m-auto mb-2">
                                {/* Pie chart 2 meses antes */}
                                <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {past2Month}</h5>
                                <Chart
                                  options={pieDataLastMonth.options}
                                  series={pieDataLastMonth.series}
                                  type="pie"
                                  className='mt-2'
                                />
                              </div>
                            </div>
                          </div>

                          <div className='mt-3 text-belmeny division' />

                          <div className="row">
                            <div className="col-12">
                              <div className="m-auto" style={{ width: "102%" }}>
                                <h5 className="text-center text-belmeny mt-4">Top Vendedores</h5>
                                <Chart
                                  options={topData.options}
                                  series={topData.series}
                                  type="bar"
                                  width="100%"
                                  height="500px"
                                />
                              </div>
                              <div className='mt-3' />
                              </div>
                              </div>
                         {/* <div className="row">
                            
                              <div className="m-auto" style={{ width: "102%" }}>
                                 <h5 className="text-center text-belmeny mt-4">Top Clientes</h5>
                                <Chart
                                  options={topCliente.options}
                                  series={topCliente.series}
                                  type="bar"
                                  width="100%"
                                  height="500px"
                                />*/}
                             
                             <div className='mt-3 text-belmeny division' />
                            <div className="row">
                          
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Porcentaje de Top Productos Comprados por Cliente</h5>
                              <h5 className="text-center text-ingco">Promedio INGCO: {porcentajeVendedor.porcen_ingco}%</h5>
                              <h5 className="text-center text-belmeny">Promedio VERT: {porcentajeVendedor.porcen_vert}%</h5>
                              <Chart
                                options={porcentajeCliente.options}
                                series={porcentajeCliente.series}
                                type="bar"
                                width="100%"
                                height="800px"
                              />
                            
                            
                            </div>
                              <div className='mt-5 text-belmeny division' />
                              <div className="mt-2 text-end">
                                <a href="https://www.facebook.com/belmeny.vert/" target={'_blank'} rel='noreferrer' className='fb-btn text-belmeny me-3'>
                                  <span className='fs-1'><FaFacebook /></span>
                                </a>
                                <a href="https://twitter.com/vert_productos?lang=es" target={'_blank'} rel='noreferrer' className='tw-btn text-belmeny me-3'>
                                  <span className='fs-1'><FaTwitter /></span>
                                </a>
                                <a href="https://www.instagram.com/vert.productos/?hl=es" target={'_blank'} rel='noreferrer' className='ig-btn text-belmeny me-3'>
                                  <span className='fs-1'><FaInstagram /></span>
                                </a>
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>

                    <div className="d-lg-xl-view">
                      <div className="dashboard-title mt-2 mb-3">
                        <h4 className='bg-belmeny text-light px-5 rounded-pill'>Metas establecidas para el mes en curso</h4>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                          <h5 className="text-center text-belmeny mt-4">Ventas anuales</h5>
                          <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            width="100%"
                            height="100%"
                          />
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 text-light">
                          {/* GLOBAL */}
                          <div className="card rounded-card drop-shadow-sm bg-belmeny">
                            <div className="card-body rounded-card py-4">
                              <div className="row">
                                <div className="col-4">
                                  <img src={belmenyLogo} alt="" className='w-100 pb-2' />
                                </div>
                                <div className="col">
                                  <h3 className="text-end card-title fw-normal">Meta: ${formatNumber((metas.ingco + metas.vert + metas.wadfow))}</h3>
                                </div>
                              </div>
                              <h4 className="card-text text-end fw-normal fs-4">Ventas: ${formatNumber(metas.total_vendido)} ≈ {formatNumber((metas.total_vendido / (metas.ingco + metas.vert + metas.wadfow)) * 100)}%</h4>
                            </div>
                          </div>

                          {/* WADFOW */}
                          <div className="card mt-5 drop-shadow-sm rounded-card bg-belmeny">
                            <div className="card-body rounded-card py-4">
                              <div className="row">
                                <div className="col-4">
                                  <img src={wadfowLogo} alt="" className='w-100' />
                                </div>
                                <div className="col">
                                  <h3 className="text-end card-title fw-normal pt-1">Ventas: ${formatNumber(metas.VentasWadfow)}</h3>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* VERT */}
                          <div className="card mt-5 drop-shadow-sm rounded-card bg-belmeny">
                            <div className="card-body rounded-card py-4">
                              <div className="row">
                                <div className="col-4">
                                  <img src={vertLogo} alt="" className='w-100' />
                                </div>
                                <div className="col">
                                  <h3 className="text-end card-title fw-normal pt-1">Ventas: ${formatNumber(metas.VentasVert)}</h3>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* INGCO */}
                          <div className="card mb-3 mt-5 rounded-card drop-shadow-sm bg-belmeny">
                            <div className="card-body rounded-card py-4">
                              <div className="row">
                                <div className="col-4">
                                  <img src={ingcoLogo} alt="" className='pt-2 w-100' />
                                </div>
                                <div className="col">
                                  <h3 className="text-end card-title fw-normal">Ventas: ${formatNumber(metas.VentasIngco)}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-5 text-belmeny division' />
                        </div>
                      </div>

                      <hr className="my-3" />

                      <div className="row">
                        <div className="col-sm-3">
                          <div className="w-80 m-auto mb-2">
                            <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {lastMonth}</h5>
                            <Chart
                              options={pieDataActualMonth.options}
                              series={pieDataActualMonth.series}
                              type="pie"
                              className='mt-2'
                            />
                          </div>

                          <div className="w-80 m-auto mb-2">
                            <h5 className="text-center text-belmeny mt-4">Información sobre cobranzas {past2Month}</h5>
                            <Chart
                              options={pieDataLastMonth.options}
                              series={pieDataLastMonth.series}
                              type="pie"
                              className='mt-2'
                            />
                          </div>
                        </div>

                        <div className="col-sm-9">
                          <div className="m-auto" style={{ width: "102%" }}>
                            <h5 className="text-center text-belmeny mt-4">Top Vendedores</h5>
                            <Chart
                              options={topData.options}
                              series={topData.series}
                              type="bar"
                              width="100%"
                              height="500px"
                            />
                          </div>
                          <div className='mt-5 ' />
                          <div className='row'>
                          
                    {/*      <div className="m-auto" style={{ width: "102%" }}>
                            <h5 className="text-center text-belmeny mt-4">Top Clientes</h5>
                            <Chart
                              options={topCliente.options}
                              series={topCliente.series}
                              type="bar"
                              width="100%"
                              height="500px"
                            />
                          </div>*/} 
                          
                          </div>
                          <div className='mt-3 text-belmeny division' />
                            <div className="row">
                          
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Porcentaje de Top Productos Comprados por Cliente</h5>
                              <h5 className="text-center text-ingco">Promedio INGCO: {porcentajeVendedor.porcen_ingco}%</h5>
                              <h5 className="text-center text-belmeny">Promedio VERT: {porcentajeVendedor.porcen_vert}%</h5>
                              <Chart
                                options={porcentajeCliente.options}
                                series={porcentajeCliente.series}
                                type="bar"
                                width="100%"
                                height="800px"
                              />
                            
                            </div>
                            </div>
                          <div className='mt-5 text-belmeny division' />
                          <div className="text-end">
                            <a href="https://www.facebook.com/belmeny.vert/" target={'_blank'} rel='noreferrer' className='fb-btn text-belmeny me-3'>
                              <span className='fs-1'><FaFacebook /></span>
                            </a>
                            <a href="https://twitter.com/vert_productos?lang=es" target={'_blank'} rel='noreferrer' className='tw-btn text-belmeny me-3'>
                              <span className='fs-1'><FaTwitter /></span>
                            </a>
                            <a href="https://www.instagram.com/vert.productos/?hl=es" target={'_blank'} rel='noreferrer' className='ig-btn text-belmeny me-3'>
                              <span className='fs-1'><FaInstagram /></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>
    </>

  );
}
