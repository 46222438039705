import React, { useState } from 'react';
import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';
import { Modal, Form, Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ListaPrecioVertXLS } from './ListaPrecioVertVendedorXLS';
import { ListaPrecioIngcoXLS } from './ListaPrecioIngcoVendedorXLS';
import { ListaPrecioWadfowXLS } from './ListaPrecioWadfowVendedorXLS';
import userIcon from '../../assets/img/user-icon-resize.webp';

export const PriceListView2 = () => {
    const { user, getToken } = AuthUser();
    const [showToast, setShowToast] = useState(true);

    return (
        <Layout>
          <div className="container-fluid mt-3">
            <div className="row">
                <div className="col">
                  <div className="text-belmeny">
                    <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                    <h3>{user.Nombre}</h3>
                    <h5><i>{user.Usuario}</i></h5>
                  </div>
                </div>
                <div className="col">
                  <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow-sm' />
                </div>

                <div className="dashboard-title mt-2 mb-3">
                  <h4 className='bg-belmeny text-light px-5 rounded-pill'>Listas de Precios</h4>
                  <h5 className='bg-belmeny text-light px-5 rounded-pill mt-2'>La lista de precios tarda en generarse de 2 a 5 minutos</h5>
                </div>

                {/* Toast */}
                <ToastContainer position="top-end" className="p-3 mt-5">
                  <Toast onClose={() => setShowToast(false)} show={showToast}>
                    <Toast.Header>
                      <strong className="me-auto text-danger">⚠️ AVISO ⚠️</strong>
                      <small>Hace un momento</small>
                    </Toast.Header>
                    <Toast.Body>La lista de precios tarda en generarse de 2 a 5 minutos, por favor esperar hasta que se genere, no precione nada hasta finalizar...Si no funciona intente mas tarde o pruebe desde otra conexion</Toast.Body>
                  </Toast>
                </ToastContainer>
                {/* End Toast */}

                <div className="row">
                  {/* VERT */}
                  <div className="col d-flex d-none d-md-block"> {/* Hide on screens smaller than medium (md) */}
                    <ListaPrecioVertXLS/>
                  </div>

                  {/* INGCO */}
                  <div className="col d-flex d-none d-md-block"> {/* Hide on screens smaller than medium (md) */}
                    <ListaPrecioIngcoXLS/>
                  </div>

                  {/* WADFOW */}
                  <div className="col d-flex d-none d-md-block"> {/* Hide on screens smaller than medium (md) */}
                    <ListaPrecioWadfowXLS/>
                  </div>
                </div>

            {/* Body for mobile version */}
            <div className="container-fluid d-block d-sm-block d-md-none">
              <div className="my-4">
                {/* VERT */}
                <div className="col d-flex mx-auto portada">
                  <ListaPrecioVertXLS/>
                </div>

                {/* INGCO */}
                <div className="col d-flex mx-auto portada">
                  <ListaPrecioIngcoXLS/>
                </div>

                {/* WADFOW */}
                <div className="col d-flex mx-auto portada">
                  <ListaPrecioWadfowXLS/>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Layout>
    );
};