import React, { useState } from 'react'

import AuthUser from '../../components/AuthUser';
import { getListaPrecioCadenas } from '../../api/requestProductos';

const ExcelJS = require("exceljs")

export const ListaPrecioIngcoXLS = () => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const [data, setData] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true)

  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  const exportExcelFile = async () => {
    setEnableBtn(false)
    const resProductos = await getListaPrecioCadenas(user.CodVendedor, ZonasVenta, 'INGCO')
    setData(resProductos)
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet("Lista de precios INGCO")

    const encabezadoRow = sheet.getRow(1)

    encabezadoRow.height = 25
    encabezadoRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FBA10B' }
    }
    encabezadoRow.font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' },
    }

    sheet.columns = [
      { header: "Codigo", key: "codigo", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Nombre", key: "nombre", width: 40, style: { alignment: { vertical: 'middle' } } },
      { header: "Precio", key: "precio", width: 10, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Existencia", key: "existencia", width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Pedido Sugerido", key: "ventaMinima", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Imagen", key: "imageId2", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Codigo de Barras", key: "codigoBarras", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Grupo", key: "grupo", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Subgrupo", key: "subgrupo", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
    ]

    const promise = Promise.all(
      resProductos.map(async (product, index) => {
        const rowNumber = index + 1
        sheet.addRow({
          rutaImagen: product.RutaImagen,
          codigo: product.Codigo,
          nombre: product.Nombre,
          precio: product.Precio,
          existencia: product.Existencia,
          ventaMinima: product.VentaMinima,
          codigoBarras: product.CodigoBarras,
          grupo:product.Grupo,
          subgrupo:product.Subgrupo
        })
        const result = await toDataURL(product.RutaImagen)
        const splitted = product.RutaImagen.split(".")
        const extName = splitted[splitted.length - 1]

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        })

        sheet.addImage(imageId2, {
          tl: { col: 5, row: rowNumber },
          ext: { width: 120, height: 120 },
        })
        sheet.getRow(rowNumber + 1).height = 95
      })
    )

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = "Lista de Precio Cadenas INGCO.xlsx"
        anchor.click()
        window.URL.revokeObjectURL(url)
        setEnableBtn(true)
      })
    })
  }

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (enableBtn) ?
            <button onClick={exportExcelFile} className='btn btn-hoverIngco btn-sm mb-2 w-100'><strong>Generar Lista de Precios INGCO</strong></button>
            :
            <button className='btn btn-hoverIngco btn-sm mb-2 w-100 disabled' disabled><strong>Generar Lista de Precios INGCO</strong></button>
        }
      </div>
    </>
  )
}
