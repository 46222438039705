import React, { useState } from 'react'

import AuthUser from '../../components/AuthUser';
import { getListaPrecioCadenas } from '../../api/requestProductos';
import { Toast, ToastContainer } from 'react-bootstrap';
import { getCobranzasVendedorXLSX } from '../../api/requestCobranzas';

const ExcelJS = require("exceljs")

export const CobranzasPendientesXLS = ({codvendedor}) => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const [data, setData] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true)
  const [showToast, setShowToast] = useState(true)
  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  const exportExcelFile = async () => {
    setEnableBtn(false)
    const resCobranzas = await getCobranzasVendedorXLSX(codvendedor);
    setData(resCobranzas)
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Lista de Cobranzas Pendientes",{
      views: [{ state: "frozen", ySplit: 1 }],
      
    });

    const encabezadoRow = sheet.getRow(1)

    encabezadoRow.height = 25
    encabezadoRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0041FF' }
    }
    encabezadoRow.font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    
    sheet.columns = [
      { header: "Factura", key: "factura", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Rif", key: "rif", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Cliente", key: "cliente", width: 55, style: { alignment: { vertical: 'middle' } } },
      { header: "Monto Total", key: "total", width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Fecha Documento", key: "facturacion", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Monto Pendiente", key: "pendiente", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Abonado", key: "abonado", width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Fecha Vencimiento", key: "vencimiento", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
    ]

    const promise = Promise.all(
        resCobranzas.map(async (cobranza, index) => {
        const rowNumber = index + 1
        const fecha=new Date(cobranza.FechaDocumento).toLocaleString('en-GB', { timeZone: 'UTC' }).split(',')[0]
        const fechaVencimiento=new Date(cobranza.FechaVencimiento).toLocaleString('en-GB', { timeZone: 'UTC' }).split(',')[0]
        sheet.addRow({
          factura: cobranza.Documento,
          rif: cobranza.codcliente,
          cliente: cobranza.nombrecli,
          total: cobranza.TotalFact,
          facturacion: fecha,
          pendiente: cobranza.TotalPend,
          abonado: cobranza.Abonado,
          vencimiento:fechaVencimiento,
        })
      })
    )

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = "Lista de Cobranzas Pendientes.xlsx"
        anchor.click()
        window.URL.revokeObjectURL(url)
        setEnableBtn(true)
      })
    })
  }

  return (
    <>
      <div className='d-md-block d-none ' >
        {
          (enableBtn) ?
            
            <button onClick={exportExcelFile} className='btn btn-primary btn-sm mb-2 w-100'><strong>Generar Cobranzas Pendientes XLS</strong></button>

            :
            <div>
            <ToastContainer position="top-end" className="p-3 mt-5">
              <Toast onClose={() => setShowToast(false)} show={showToast}>
                <Toast.Header>
                  <strong className="me-auto text-danger">⚠️ AVISO ⚠️</strong>
                  <small>Hace un momento</small>
                </Toast.Header>
                <Toast.Body>La lista de productos en EXCEL tarda en generarse de 1 a 3 minutos, por favor esperar hasta que se genere...</Toast.Body>
              </Toast>
            </ToastContainer>
            <button className='btn btn-primary btn-sm mb-2 w-100 disabled' disabled><strong>Generar Cobranzas Pendientes XLS</strong></button>
            </div>
        }
        
      </div>
    </>
  )
}
