import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
PRESUPUESTOS
==================================================================================
*/

export async function getPresupuestos(Codigo) {
  try {
    const res = await axios.get(baseURL + `/getPresupuestos?Vendedor[eq]=${Codigo}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getPresupuestoEncabezado(Vendedor, Documento) {
  try {
    const res = await axios.get(baseURL + `/getPresupuestos?Vendedor[eq]=${Vendedor}&Documento[eq]=${Documento}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function SearchPresupuesto(Documento, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getPresupuestosPorDocumento?Documento=${Documento}&Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function SearchPresupuestoCli(Vendedor, Cliente) {
  try {
    const res = await axios.get(baseURL + `/getPresupuestoPorCliente?Vendedor=${Vendedor}&NombreCliente=${Cliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function SearchPresupuestobyFechas(Vendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getPresupuestoPorFecha?Vendedor=${Vendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductosPresupuestos(Documento) {
  try {
    const res = await axios.get(baseURL + `/getProductosPresupuestos?Documento=${Documento}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getSubtotalPresupuesto(Documento) {
  try {
    const res = await axios.get(baseURL + `/getSubtotalPresupuesto?Documento=${Documento}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getProductosPresupuestosPDF(Documento) {
  try {
    const res = await axios.get(baseURL + `/getProductosPresupuestosPDF?Documento=${Documento}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function postPresupuestoEncabezado(Documento, Codcliente, NombreCliente, Vendedor, FormaPago, FechaPresupuesto, Monto, Convertido, Descuento, DiasPromocion, tipoPromocion, montoPromocion, Modificar) {
  try {
    const res = await axios.post(baseURL + `/postPresupuestoEncabezado?Documento=${Documento}&Codcliente=${Codcliente}&NombreCliente=${NombreCliente}&Vendedor=${Vendedor}&FormaPago=${FormaPago}&FechaPresupuesto=${FechaPresupuesto}&Monto=${Monto}&Convertido=${Convertido}&Descuento=${Descuento}&DiasPromocion=${DiasPromocion}&TipoPromocion=${tipoPromocion}&MontoPromocion=${montoPromocion}&Modificar=${Modificar}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function postPresupuestoEncabezadoModificado(Documento, Codcliente, NombreCliente, Vendedor, FormaPago, FechaPresupuesto, Monto, Convertido, Descuento, DiasPromocion, tipoPromocion, montoPromocion) {
  try {
    const res = await axios.post(baseURL + `/postPresupuestoEncabezadoModificado?Documento=${Documento}&Codcliente=${Codcliente}&NombreCliente=${NombreCliente}&Vendedor=${Vendedor}&FormaPago=${FormaPago}&FechaPresupuesto=${FechaPresupuesto}&Monto=${Monto}&Convertido=${Convertido}&Descuento=${Descuento}&DiasPromocion=${DiasPromocion}&TipoPromocion=${tipoPromocion}&MontoPromocion=${montoPromocion}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function convertPresupuesto(Documento, formaFiscal, Comentario, NombreVendedor) {
  try {
    const res = await axios.post(baseURL + `/convertPresupuesto?Documento=${Documento}&formaFiscal=${formaFiscal}&Comentario=${Comentario}&NombreVendedor=${NombreVendedor}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function deletePresupuestoEncabezadoDetalle(Documento, Codcliente) {
  try {
    const res = await axios.delete(baseURL + `/deletePresupuestoEncabezadoDetalle?Documento=${Documento}&Codcliente=${Codcliente}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function updatePrices(Documento, Codcliente) {
  try {
    const res = await axios.post(baseURL + `/updatePrices?Documento=${Documento}&rif=${Codcliente}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function copyPresupuestosToTemp(Documento) {
  try {
    const res = await axios.post(baseURL + `/copyPresupuestosToTemp?Documento=${Documento}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}