import axios from "axios";
import { baseURL } from "./ApiURL"

/*
==================================================================================
LINK APK
==================================================================================
*/
export async function getApkLink(CodVendedor) {
  try {
    const res = await axios.get(baseURL + `/linkApk`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
REGISTRO O ACTUALIZACIÓN DE SESIÓN
==================================================================================
*/
export async function registerOrUpdateSession(CodVendedor, NombreVendedor, fechahora_sesion) {
  try {
    const res = await axios.post(baseURL + `/registerOrUpdateSession?CodVendedor=${CodVendedor}&NombreVendedor=${NombreVendedor}&fechahora_sesion=${fechahora_sesion}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function insertZonasVendedor(Zona, CodVendedor, CodSupervisor, NombreVendedor) {
  try {
    const res = await axios.post(baseURL + `/insertZonaVendedor?Sector=${Zona}&CodVendedor=${CodVendedor}&CodSupervisor=${CodSupervisor}&Nombre=${NombreVendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}
/*
==================================================================================
INFO DEL VENDEDOR Y USUARIO
==================================================================================
*/
export async function getVendedor(CodVendedor) {
  try {
    const res = await axios.get(baseURL + `/getVendedor?CodVendedor=${CodVendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getUsuario(Usuario) {
  try {
    const res = await axios.get(baseURL + `/getUsuario?Usuario=${Usuario}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
REQUEST PARA PASAR DE PÁGINA (FUNCIONA EN TODOS)
==================================================================================
*/
export async function RequestPage(url) {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
REQUEST PARA OBTENER IMAGENES
==================================================================================
*/
export async function getImg(id) {
  try {
    const res = await axios.get(baseURL + `/getImg?id=${id}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

/*
==================================================================================
DASHBOARD DEL VENDEDOR
==================================================================================
*/
export async function getMetasPorVendedor(Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getMetaVendedor?vendedor[eq]=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getTopVendedoresZona(Zona, PeriodoInicio, PeriodoFin) {
  try {
    const res = await axios.get(baseURL + `/getTopVendedoresZona?zona=${Zona}&PeriodoInicio=${PeriodoInicio}&PeriodoFin=${PeriodoFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getInfoCobranza(Vendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getInfoCobranza?CodigoVendedor=${Vendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasAnualesEnCurso(Vendedor, year) {
  try {
    const res = await axios.get(baseURL + `/getVentasAnualesEnCurso?CodigoVendedor=${Vendedor}&year=${year}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasVendedor(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasPasados?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopClientesVendedor(year, mes, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getTopClientesVendedor?year=${year}&vendedor=${vendedor}&mes=${mes}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getPorcentajeClientes(vendedor) {
  try {
    const res = await axios.get(baseURL + `/getPorcentajeClientes?codVendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getPorcentajeClientes2(vendedor) {
  try {
    const res = await axios.get(baseURL + `/getPorcentajeClientesCompleto?codVendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getPorcentajeVendedor(vendedor) {
  try {
    const res = await axios.get(baseURL + `/getPorcentajeVendedor?CodVendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}


/*
==================================================================================
BUZON Y SOLICITUD DE EXHIBIDORES
==================================================================================
*/
export async function postSolicitud(nro_pedido, usuario, cliente, marca, motivoSolicitud, tipoExhibidor, ancho, alto, materialExhibidor, montoExhibidor, aprobacion_supervisor, aprobacion_gerencia, fecha_solicitud) {
  try {
    const res = await axios.post(baseURL + `/postSolicitud?nro_pedido=${nro_pedido}&usuario=${usuario}&cliente=${cliente}&marca=${marca}&motivoSolicitud=${motivoSolicitud}&tipoExhibidor=${tipoExhibidor}&ancho=${ancho}&alto=${alto}&materialExhibidor=${materialExhibidor}&montoExhibidor=${montoExhibidor}&aprobacion_supervisor=${aprobacion_supervisor}&aprobacion_gerencia=${aprobacion_gerencia}&fecha_solicitud=${fecha_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function getBuzon() {
  try {
    const res = await axios.get(baseURL + `/getBuzon`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function getBuzonPreSolicitud() {
  try {
    const res = await axios.get(baseURL + `/getBuzonPreSolicitud`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function getMessage(nro_solicitud) {
  try {
    const res = await axios.get(baseURL + `/getMessage?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function getMessagePreSolicitud(id_presolicitud) {
  try {
    const res = await axios.get(baseURL + `/getMessagePreSolicitud?id_presolicitud=${id_presolicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

// Presolicitudes
export async function actualizarLeidoVendedorPreSolicitud(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoVendedorPreSolicitud?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function actualizarLeidoSupervisorPreSolicitud(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoSupervisorPreSolicitud?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function actualizarLeidoGerenciaPreSolicitud(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoGerenciaPreSolicitud?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

// Solicitudes normales
export async function actualizarLeidoVendedor(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoVendedor?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function actualizarLeidoSupervisor(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoSupervisor?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function actualizarLeidoGerencia(nro_solicitud) {
  try {
    const res = await axios.put(baseURL + `/actualizarLeidoGerencia?nro_solicitud=${nro_solicitud}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function AprovOrDenyRequest(nro_solicitud, typeUser, decision, usuario, observacion) {
  if (decision === 'Aprobado' && typeUser === 'supervisor') {
    try {
      const res = await axios.put(baseURL + `/AprovOrDeny?nro_solicitud=${nro_solicitud}&typeUser=${typeUser}&decision=${decision}&usuario=${usuario}`);
      return res;
    } catch (error) {
      console.log(error)
    }
  }
  else if (decision === 'Denegado' && typeUser === 'supervisor') {
    try {
      const res = await axios.put(baseURL + `/AprovOrDeny?nro_solicitud=${nro_solicitud}&typeUser=${typeUser}&decision=${decision}&usuario=${usuario}&observacion=${observacion}`);
      return res;
    } catch (error) {
      console.error(error)
    }
  }
  else if (decision === 'Aprobado' && typeUser === 'gerente') {
    try {
      const res = await axios.put(baseURL + `/AprovOrDeny?nro_solicitud=${nro_solicitud}&typeUser=${typeUser}&decision=${decision}&usuario=${usuario}`);
      return res;
    } catch (error) {
      console.error(error)
    }
  }
  else if (decision === 'Denegado' && typeUser === 'gerente') {
    try {
      const res = await axios.put(baseURL + `/AprovOrDeny?nro_solicitud=${nro_solicitud}&typeUser=${typeUser}&decision=${decision}&usuario=${usuario}&observacion=${observacion}`);
      return res;
    } catch (error) {
      console.error(error)
    }
  }
}

export async function postPreSolicitud(cliente, rif, sugerencia, marca, imagenes_id, fecha_solicitud, usuario) {
  try {
    const res = await axios.post(baseURL + `/postPreSolicitud?cliente=${cliente}&rif=${rif}&sugerencia=${sugerencia}&marca=${marca}&imagenes_id=${imagenes_id}&fecha_solicitud=${fecha_solicitud}&usuario=${usuario}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

// Creación de videos
export async function postNewVideo(title, description, link, today) {
  try {
    const res = await axios.post(baseURL + `/postNewVideo?titulo=${title}&descripcion=${description}&link=${link}&fecha_subida=${today}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

//Obtención de videos
export async function getVideos() {
  try {
    const res = await axios.get(baseURL + `/getVideos`);
    return res;
  } catch (error) {
    console.error(error)
  }
}

//Obtención de videos
export async function registerOrUpdateViewer(usuario, id_video, startedOrEnded) {
  try {
    const res = await axios.patch(baseURL + `/registerOrUpdateViewer?usuario=${usuario}&id_video=${id_video}&startedOrEnded=${startedOrEnded}`);
    return res;
  } catch (error) {
    console.error(error)
  }
}